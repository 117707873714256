import React from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "react-table";
import { KTSVG, QUERIES } from "../../../../_metronic/helpers";
import { CampaignTypeEnum } from "../../../enums/CampaignTypeEnum";
import { capitalizeFirstLetter } from "../../../helpers/general";
import { Campaign } from "../../../models/Campaign";
import { Restricted } from "../../../modules/auth/AuthAccessControl";
import { ActionsCell } from "../../../modules/table/columns/ActionsCell";
import { CustomHeader } from "../../../modules/table/columns/CustomHeader";
import { TextCell } from "../../../modules/table/columns/TextCell";

const CampaignsColumns: ReadonlyArray<Column<Campaign>> = [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title="Name" className="min-w-125px" />
    ),
    id: "name",
    Cell: ({ ...props }) => (
      <TextCell text={props.data[props.row.index].name} />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title="Type" className="min-w-125px" />
    ),
    id: "type",
    Cell: ({ ...props }) => (
      <TextCell
        text={
          capitalizeFirstLetter(props.data[props.row.index].type) +
          (props.data[props.row.index].type === CampaignTypeEnum.MASTER
            ? " (" + props.data[props.row.index].promo_code_usage + ")"
            : "")
        }
      />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Code count"
        className="min-w-125px"
      />
    ),
    id: "code_count",
    Cell: ({ ...props }) => (
      <TextCell text={props.data[props.row.index].code_count} />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Start date"
        className="min-w-125px"
      />
    ),
    id: "start_date",
    Cell: ({ ...props }) => (
      <TextCell text={props.data[props.row.index].start_date} />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="End date"
        className="min-w-125px"
      />
    ),
    id: "end_date",
    Cell: ({ ...props }) => (
      <TextCell text={props.data[props.row.index].end_date} />
    ),
  },
  {
    Header: (props) => (
      <Restricted to="manage-iam">
        <CustomHeader
          tableProps={props}
          title="Actions"
          className="text-end min-w-100px"
        />
      </Restricted>
    ),
    id: "actions",
    Cell: ({ ...props }) => {
      const navigate = useNavigate();

      return (
        <Restricted to="manage-iam">
          <ActionsCell
            id={props.data[props.row.index].id}
            path={"campaigns"}
            queryKey={QUERIES.CAMPAIGNS_LIST}
            showView={true}
            showEdit={false}
            showDelete={false}
            title=""
            text={``}
          />
          <a
            className="btn btn-icon btn-sm btn-active-light-danger"
            onClick={async () =>
              navigate(`/campaigns/${props.data[props.row.index].id}/delete`)
            }
          >
            <KTSVG
              path={"/media/icons/duotune/general/gen027.svg"}
              className={"svg-icon-danger"}
            />
          </a>
        </Restricted>
      );
    },
  },
];

export { CampaignsColumns };
