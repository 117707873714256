import axios, { AxiosError, AxiosResponse } from "axios";
import clsx from "clsx";
import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AlertMessageGenerator } from "../../../app/helpers/AlertMessageGenerator";
import { GenericErrorMessage } from "../../../app/helpers/form";
import { extractErrors } from "../../../app/helpers/requests";
import { Actions, B360ToastType } from "../../../app/helpers/variables";
import { useB360App } from "../../../app/modules/general/B360App";

export type KTCardHeaderProps = {
  className?: string;
  text: string;
  id?: string;
  bg?: string;
  text_color?: string;
  collapse?: boolean;
  target_id?: string;
  icon?: string;
  icon_style?: string;
  actions?: any;
};

export type ExportUrl = {
  data: {
    status: string;
    url?: string;
  };
};

const KTCardHeader: FC<KTCardHeaderProps> = ({
  className,
  text,
  id,
  bg,
  text_color,
  collapse = false,
  target_id,
  icon,
  icon_style,
  actions,
}) => {
  let opts: any = {};
  if (collapse) {
    opts["role"] = "button";
    opts["data-bs-toggle"] = "collapse";
    opts["data-bs-target"] = `#${target_id}`;
    opts["aria-expanded"] = "true";
    opts["aria-controls"] = `${target_id}`;
  }

  const b360App = useB360App();
  const exportObjects = async (
    endpoint: String
  ): Promise<ExportUrl | AxiosError | undefined> => {
    let url = `${endpoint}`;

    return axios
      .get(url)
      .then((response: AxiosResponse) => response.data)
      .catch((error) => {
        return error;
      });
  };

  const exportHandler = (endpoint: string) => {
    // we already have the query for our export request ready based on filters
    // we just need to do the api call
    genericExportHandler(b360App, endpoint);
  };

  const genericExportHandler = (b360App: any, exportEndpoint: string) => {
    // we already have the query for our export request ready based on filters
    // we just need to do the api call
    exportObjects(exportEndpoint).then((response) => {
      if (axios.isAxiosError(response)) {
        // we need to show the errors
        b360App.setAlert({
          message: extractErrors(response).join(" "),
          type: B360ToastType.ERROR,
        });
      } else if (response === undefined) {
        // show generic error message
        b360App.setAlert({
          message: GenericErrorMessage,
          type: B360ToastType.ERROR,
        });
      } else {
        // we need to check the status of the response
        if (
          response.data.status === "ready" &&
          response.data.url !== undefined
        ) {
          b360App.setAlert({
            message: new AlertMessageGenerator(
              "",
              Actions.EXPORT,
              B360ToastType.SUCCESS
            ).message,
            type: B360ToastType.SUCCESS,
          });

          const link = document.createElement("a");
          link.href = response.data.url;

          link.click();
        } else if (response.data.status === "pending") {
          b360App.setAlert({
            message: new AlertMessageGenerator(
              "",
              Actions.EXPORT,
              B360ToastType.PENDING
            ).message,
            type: B360ToastType.PENDING,
          });
        }
      }
    });
  };

  return (
    <div
      id={id}
      className={clsx(`card-header`, className && className, bg && `bg-${bg}`)}
      {...opts}
    >
      <div className="card-title d-flex align-items">
        {icon !== undefined ? (
          <span className="me-2">
            <i
              className={clsx(icon, icon_style !== undefined ? icon_style : "")}
            ></i>
          </span>
        ) : (
          ""
        )}

        <h3 className={`card-label text-${text_color || "primary"}`}>{text}</h3>
      </div>

      <div className="card-toolbar">
        {actions && actions.length > 0 ? (
          <>
            {actions.map((action: any, index: any) => {
              if (action.title === "Export") {
                return (
                  <Button
                    type="button"
                    className={clsx("btn btn-light-info fs-6 ms-2", className)}
                    title="Export"
                    onClick={() => exportHandler(action.endpoint)}
                    key={`action-header-${index}`}
                  >
                    <i className={clsx("fa fs-4", "fa-download", "pe-0")}></i>{" "}
                    {action.headline ?? ""}
                  </Button>
                );
              } else {
                return (
                  <Link
                    to={`${action.url}`}
                    className={clsx(`btn btn-${action.color} btn-sm fs-6 ms-2`)}
                    title={`${action.title}`}
                    key={`action-header-${index}`}
                  >
                    <i
                      className={clsx("fa fs-4", `${action.icon}`, "pe-0")}
                    ></i>{" "}
                    {action.headline ?? ""}
                  </Link>
                );
              }
            })}{" "}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export { KTCardHeader };
