/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { ResetPassword } from "./components/ResetPassword";

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = "none";
    return () => {};
  }, []);

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
      <div
        className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        // className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            "/media/illustrations/sketchy-1/14.png"
          )})`,
        }}
      >
        {/* begin::Content */}
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <h1 className="text-center mb-5">
            Welcome to Burger King Promo Code Management Dashboard
          </h1>

          {/* begin::Wrapper */}
          <div className="w-lg-s00px w-sm-400px bg-body rounded shadow-sm p-10 p-lg-15 m-5">
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
      </div>

      <div
        className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/misc/auth-bg.png")})`,
        }}
      >
        <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
          {/*<a href="#" className="mb-0 mb-lg-12">*/}
          {/*  <img*/}
          {/*    alt="Logo"*/}
          {/*    src={toAbsoluteUrl("/media/logos/bk-logo.png")}*/}
          {/*    className="h-60px h-lg-75px"*/}
          {/*  />*/}
          {/*</a>*/}

          {/* begin::Logo */}
          <a href="#">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/bk-logo.png")}
              className="theme-dark-show h-100px"
            />
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/bk-logo.png")}
              className="theme-light-show h-100px"
            ></img>
          </a>
          {/* end::Logo */}

          {/*<img*/}
          {/*  className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"*/}
          {/*  src={toAbsoluteUrl("/media/misc/auth-screens.png")}*/}
          {/*  alt=""*/}
          {/*/>*/}

          {/*<h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7 mt-12">*/}
          {/*  Lorem Ipsum*/}
          {/*</h1>*/}

          <div className="d-none d-lg-block text-white fs-base text-center mb-7 mt-12">
            Welcome to the Promo Codes Management Platform for Burger King KSA.
            This platform is designed to empower you, whether as a campaign
            manager generating and overseeing promo codes or as a restaurant
            manager validating codes and serving our customers. Each action you
            take here enhances our brand, streamlines our operations, and
            contributes to memorable experiences for our customers. Let's
            continue to make Burger King KSA the choice of preference, one promo
            code at a time.
          </div>
        </div>
      </div>
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
