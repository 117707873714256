import React, { Dispatch } from "react";

export const genericOnChangeHandler = (
  e: any,
  form: any,
  setForm: React.Dispatch<React.SetStateAction<any>>
) => {
  const value = e.target.value;
  const name = e.target.name;

  if (name) {
    setForm({
      ...form,
      [name]: value,
    });
  }
};

export const GenericErrorMessage: string =
  "Oops! Something went wrong. Try again later.";

export const genericMultiSelectOnChangeHandler = (
  e: any,
  form: any,
  setForm: React.Dispatch<React.SetStateAction<any>>,
  key: string
) => {
  if (e.length > 0) {
    setForm({ ...form, [key]: e.map((entity: any) => entity.id) });
  } else {
    setForm({ ...form, [key]: [] });
  }
};

export const genericDateOnChangeHandler = (
  date: Date | null,
  form: any,
  setForm: Dispatch<React.SetStateAction<any>>,
  key: string
) => {
  if (date) {
    var intlDateTimeObj = new Intl.DateTimeFormat("en-US", {
      // timeZone: "Asia/Dubai",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Set this to true if you want 12-hour format with AM/PM.
      timeZoneName: "short",
    });

    const convertedDateTimeToTimeZone = intlDateTimeObj.format(date); // 09/16/2023, 06:33:15
    const convertedDateTimeSplit = convertedDateTimeToTimeZone.split(", ");

    const convertedDate = convertedDateTimeSplit[0]; // date
    const convertedTime = convertedDateTimeSplit[1].split(" ")[0]; // time

    const convertedDateSplit = convertedDate.split("/");

    const year = convertedDateSplit[2];
    const month = convertedDateSplit[0];
    const day = convertedDateSplit[1];

    // const year = date.getFullYear();
    // const month = String(date.getMonth() + 1).padStart(2, "0");
    // const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${convertedTime}`;

    setForm({ ...form, [key]: formattedDate });
  } else {
    // in case the user removed the date then we should reset it (date will be null)
    setForm({ ...form, [key]: date });
  }
};

export const genericSingleSelectOnChangeHandler = (
  e: any,
  form: any,
  setForm: Dispatch<React.SetStateAction<any>>,
  key: string
) => {
  if (e) {
    setForm({ ...form, [key]: e.id });
  } else {
    // this happens when we're trying to unselect an option
    // we need to remove the [key] property from the form and set the new value as form
    const { [key]: _, ...newForm } = form;

    setForm(newForm);
  }
};
