import React, { useEffect, useMemo } from "react";

import { KTCard, KTCardBody, QUERIES } from "../../../../_metronic/helpers";
import { KTCardHeader } from "../../../../_metronic/helpers/components/KTCardHeader";
import B360Table from "../../../components/tables/B360Table";
import { generatePageTitle } from "../../../helpers/pageTitleGenerator";
import { Sections } from "../../../helpers/sections";
import { PageTypes } from "../../../helpers/variables";
import { useB360App } from "../../../modules/general/B360App";
import { ListViewProvider } from "../../../modules/table/ListViewProvider";
import { QueryRequestProvider } from "../../../modules/table/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponseData,
  useQueryResponseLoading,
} from "../../../modules/table/QueryResponseProvider";
import { getCampaigns } from "../../../requests/Campaign";
import { CampaignsColumns } from "../core/TableColumns";

const CampaignIndex = () => {
  const b360App = useB360App();

  useEffect(() => {
    b360App.setPageTitle(
      generatePageTitle(Sections.CAMPAIGNS, PageTypes.INDEX)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QueryRequestProvider>
      <QueryResponseProvider
        id={QUERIES.CAMPAIGNS_LIST}
        requestFunction={getCampaigns}
      >
        <ListViewProvider>
          <KTCard>
            <KTCardHeader
              text={"All Campaigns"}
              actions={[
                {
                  url: "/campaigns/create",
                  color: "primary",
                  title: "Create",
                  icon: "fa-plus",
                  headline: "Create campaign",
                },
              ]}
            />

            <KTCardBody>
              <IndexTable />
            </KTCardBody>
          </KTCard>
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};

const IndexTable = () => {
  const modelData = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => modelData, [modelData]);
  const columns = useMemo(
    () => CampaignsColumns,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <B360Table
      data={data}
      columns={columns}
      model={modelData.length > 0 ? modelData[0] : null}
      isLoading={isLoading}
    />
  );
};
export default CampaignIndex;
