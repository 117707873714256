export enum Sections {
  LOGIN = "Login",
  DASHBOARD = "Dashboard",
  IAM_PERMISSIONS = "Permissions",
  IAM_ROLES = "Roles",
  IAM_USERS = "Users",
  MISC_STORES = "Stores",
  CAMPAIGNS = "Campaigns",
  CODES_VALIDATOR = "Codes Validator",
}
