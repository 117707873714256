import { QrScanner } from "@yudiel/react-qr-scanner";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { Row } from "rsuite";
import * as Yup from "yup";
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers";
import { KTCardHeader } from "../../../../../_metronic/helpers/components/KTCardHeader";
import B360Checkbox from "../../../../components/forms/B360Checkbox";
import CustomFormLabel from "../../../../components/forms/CustomFormLabel";
import { HttpResponseEnum } from "../../../../enums/HttpResponseEnum";
import {
  GenericErrorMessage,
  genericOnChangeHandler,
} from "../../../../helpers/form";
import { generatePageTitle } from "../../../../helpers/pageTitleGenerator";
import { extractErrors } from "../../../../helpers/requests";
import { Sections } from "../../../../helpers/sections";
import { PageTypes } from "../../../../helpers/variables";
import { useB360App } from "../../../../modules/general/B360App";
import { validateCode } from "../../../../requests/CodeValidator";

interface FormFields {
  code: string;
  is_master_single_code: number;
  phone_number: string;
}

const defaultFormFields = {
  code: "",
  is_master_single_code: 0,
  phone_number: "",
};

const CodeValidator = () => {
  const b360App = useB360App();

  useEffect(() => {
    b360App.setPageTitle(
      generatePageTitle(Sections.CODES_VALIDATOR, PageTypes.INDEX)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [form, setForm] = useState<FormFields>(defaultFormFields);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [initialAlert, setInitialAlert] = useState<string>("");
  const [codeAlertVariant, setCodeAlertVariant] = useState<number>(0);
  const [codeAlert, setCodeAlert] = useState<string>("");
  const [usingQrCodeScanner, setUsingQrCodeScanner] = useState<boolean>(false);

  const CodeValidatorSchema = Yup.object().shape({
    code: Yup.string().required(),
    // phone_number: // TODO
  });

  const onChangeHandler = (e: any) => {
    genericOnChangeHandler(e, form, setForm);
  };

  const handleValidate = (e: any, fns: any) => {
    setCodeAlert("");

    setInitialAlert(
      "Please wait for a moment, as the operation may take some time."
    );

    validateCode(form).then((response) => {
      if (axios.isAxiosError(response)) {
        // we need to show the errors
        setFormErrors(extractErrors(response));
      } else if (response === undefined) {
        // show generic error message
        setFormErrors([GenericErrorMessage]);
      } else {
        setCodeAlert(response.message);
        setCodeAlertVariant(response.data);
      }

      fns.setSubmitting(false);
    });
  };

  const qrCodeDecode = (code: any) => {
    setForm({
      ...form,
      code: code,
    });
    // validateQrCode(endpoint).then((response) => {
    //   if (axios.isAxiosError(response)) {
    //     // we need to show the errors
    //     setFormErrors(extractErrors(response));
    //   } else if (response === undefined) {
    //     // show generic error message
    //     setFormErrors([GenericErrorMessage]);
    //   } else {
    //     setCodeAlert(response.message);
    //   }
    // });
  };

  return (
    <>
      <KTCard className="card-code-validator">
        <KTCardHeader text="Validate a Promo Code" />

        <KTCardBody>
          {formErrors.length > 0 && (
            <Alert variant="danger">
              <ul className="mb-0">
                {formErrors.map((errorMessage, index) => {
                  return <li key={index}>{errorMessage}</li>;
                })}
              </ul>
            </Alert>
          )}

          <Formik
            initialValues={form}
            validationSchema={CodeValidatorSchema}
            onSubmit={handleValidate}
            enableReinitialize
          >
            {(formik) => (
              <Form onChange={onChangeHandler} className="form-code-validator">
                <div className="mb-7">
                  <CustomFormLabel text="Code" isRequired={true} />

                  <Row>
                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 d-inline-block pe-xl-2 pe-lg-2 pe-md-2 pe-sm-2 pe-0">
                      <Field
                        className="form-control fs-base"
                        type="text"
                        placeholder="Enter the promo code"
                        name="code"
                        style={{
                          textTransform: "uppercase",
                        }}
                      />
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 d-inline-block ps-xl-2 ps-lg-2 ps-md-2 ps-sm-2 ps-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-2">
                      <Button
                        type="submit"
                        className={"btn-sm"}
                        disabled={formik.isSubmitting}
                      >
                        {formik.isSubmitting && (
                          <span
                            className="indicator-progress"
                            style={{ display: "inline-block" }}
                          >
                            <span className="spinner-border spinner-border-sm align-middle me-2" />{" "}
                            Please wait ...
                          </span>
                        )}

                        {!formik.isSubmitting && "Validate"}
                      </Button>
                    </div>

                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1s col-12 d-inline-block ps-xl-2 ps-lg-2 ps-md-2 ps-sm-2 ps-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-2">
                      <a
                        className="btn btn-icon w-100"
                        onClick={async () =>
                          setUsingQrCodeScanner((prevState) => !prevState)
                        }
                      >
                        <i
                          className="fa-solid fa-qrcode text-dark"
                          style={{ fontSize: "34px" }}
                        ></i>
                      </a>
                    </div>
                  </Row>

                  <div className="mt-1 text-danger">
                    <ErrorMessage name="code" />
                  </div>

                  <Row>
                    <div className="col-6">
                      <B360Checkbox
                        name="is_master_single_code"
                        label="Is Master Promo Code (Single)?"
                        onChangeHandler={(e) => {
                          e.stopPropagation();
                          setForm({
                            ...form,
                            is_master_single_code: Number(
                              !form.is_master_single_code
                            ),
                          });
                        }}
                        defaultValue={Boolean(form.is_master_single_code)}
                      />
                    </div>
                  </Row>

                  {form.is_master_single_code ? (
                    <Row className="mt-2">
                      <div className="col-6">
                        <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 d-inline-block pe-xl-2 pe-lg-2 pe-md-2 pe-sm-2 pe-0">
                          <Field
                            className="form-control fs-base"
                            type="text"
                            placeholder="Enter the phone number"
                            name="phone_number"
                          />
                        </div>
                      </div>
                    </Row>
                  ) : (
                    <></>
                  )}

                  {usingQrCodeScanner && (
                    <Row>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-inline-block pe-xl-2 pe-lg-2 pe-md-2 pe-sm-2 pe-0">
                        <QrScanner
                          onDecode={(result) => {
                            qrCodeDecode(result);
                          }}
                          onError={(error) => setFormErrors([error?.message])}
                        />
                      </div>
                    </Row>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </KTCardBody>
      </KTCard>

      <KTCard className="mt-10">
        <KTCardHeader text="Result" />

        <KTCardBody>
          {initialAlert && !codeAlert && (
            <Alert variant="primary">
              <p className="m-0">{initialAlert}</p>
            </Alert>
          )}

          {codeAlertVariant &&
          codeAlertVariant === HttpResponseEnum.HTTP_CREATED &&
          codeAlert ? (
            <Alert variant={"success"}>
              <p className="m-0">
                <i
                  className={"fa-regular fa-square-check text-success px-2"}
                ></i>
                {codeAlert}
              </p>
            </Alert>
          ) : (
            <></>
          )}

          {codeAlertVariant &&
          codeAlertVariant === HttpResponseEnum.HTTP_BAD_REQUEST &&
          codeAlert ? (
            <Alert variant={"danger"}>
              <p className="m-0">
                <i
                  className={"fa-regular fa-rectangle-xmark text-danger px-2"}
                ></i>
                {codeAlert}
              </p>
            </Alert>
          ) : (
            <></>
          )}

          {codeAlertVariant &&
          codeAlertVariant === HttpResponseEnum.HTTP_OK &&
          codeAlert ? (
            <Alert variant={"info"}>
              <p className="m-0">{codeAlert}</p>
            </Alert>
          ) : (
            <></>
          )}
        </KTCardBody>
      </KTCard>
    </>
  );
};

export default CodeValidator;
