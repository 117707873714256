import { FC, Suspense } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import { WithChildren } from "../../../_metronic/helpers";

export const SuspenseView: FC<WithChildren> = ({ children }) => {
  TopBarProgress.config({
    barColors: {
      "0": "blue",
    },
    barThickness: 10,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
