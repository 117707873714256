const QUERIES = {
  PERMISSIONS_LIST: "permissions-list",
  ROLES_LIST: "roles-list",
  USERS_LIST: "users-list",
  STORES_LIST: "stores-list",
  CAMPAIGNS_LIST: "campaigns-list",
  REDEEMED_CODES_LIST: "redeemed-codes-list",
};

export { QUERIES };
