import clsx from "clsx";
import React from "react";

type Props = {
  className?: string;
};

const PendingIcon: React.FC<Props> = ({ className }) => {
  return <i className={clsx("fa fs-3 text-dark", "fa-clock")}></i>;
};

export default PendingIcon;
