import axios, { AxiosError } from "axios";
import { createFormData } from "../helpers/requests";
import { CodeValidator } from "../models/Campaign";

const API_URL = process.env.REACT_APP_API_URL;
const ENDPOINT = `${API_URL}/codes/validator`;

export const validateCode = async (
  form: any
): Promise<CodeValidator | AxiosError | undefined> => {
  let formData = createFormData(form);

  return await axios
    .post(ENDPOINT, formData)
    .then((response) => response.data)
    .catch((error) => {
      return error;
    });
};

export const validateQrCode = async (
  endpoint: any
): Promise<CodeValidator | AxiosError | undefined> => {
  return await axios
    .get(endpoint)
    .then((response) => response.data)
    .catch((error) => {
      return error;
    });
};
