import axios, { AxiosError, AxiosResponse } from "axios";
import { createFormData } from "../helpers/requests";
import {
  Campaign,
  CampaignList,
  CampaignPaginate,
  CampaignTotalRedeemedCodesList,
} from "../models/Campaign";
import { CodePaginate } from "../models/Code";
import { StorePaginate } from "../models/misc/Store";

const API_URL = process.env.REACT_APP_API_URL;
export const ENDPOINT = `${API_URL}/campaigns`;
export const EXPORT_ENDPOINT = `${ENDPOINT}/export`;

export const getAllCampaigns = async (): Promise<
  CampaignList | AxiosError | undefined
> => {
  return axios
    .get(ENDPOINT + "/all?sort[]=name")
    .then((response: AxiosResponse<CampaignList>) => response.data)
    .catch((error) => {
      return error;
    });
};

export const getCampaigns = (query?: String): Promise<CampaignPaginate> => {
  let url = `${ENDPOINT}`;

  if (query) {
    url += `?${query}`;
  }

  return axios
    .get(url)
    .then((response: AxiosResponse<CampaignPaginate>) => response.data)
    .catch((error) => {
      return error;
    });
};

export const getCampaign = async (
  id: number
): Promise<Campaign | AxiosError | undefined> => {
  return await axios
    .get(ENDPOINT + "/" + id)
    .then((res) => res.data.data)
    .catch((error) => {
      return error;
    });
};

export const storeCampaign = async (
  campaign: any
): Promise<Campaign | AxiosError | undefined> => {
  let formData = createFormData(campaign);

  return await axios
    .post(ENDPOINT, formData)
    .then((res) => res.data.data)
    .catch((error) => {
      return error;
    });
};

export const updateCampaign = async (
  id: number,
  campaign: any
): Promise<Campaign | AxiosError | undefined> => {
  let formData = createFormData(campaign);

  formData.append("_method", "put");

  return await axios
    .post(ENDPOINT + "/" + id, formData)
    .then((res) => res.data.data)
    .catch((error) => {
      return error;
    });
};

export const deleteCampaign = async (
  id: number,
  campaign: any
): Promise<Campaign | AxiosError | undefined> => {
  let formData = createFormData(campaign);

  return await axios
    .post(ENDPOINT + "/" + id + "/confirm-deletion", formData)
    .then((res) => res.data.data)
    .catch((error) => {
      return error;
    });
};

export const getRedeemedCodes = (
  id: number,
  query?: String
): Promise<CodePaginate> => {
  let url = `${ENDPOINT}/${id}/codes`;

  if (query) {
    url += `?${query}`;
  }

  return axios
    .get(url)
    .then((response: AxiosResponse<StorePaginate>) => response.data)
    .catch((error) => {
      return error;
    });
};

export const getTotalRedeemedCodes = (
  id: number,
  query?: String
): Promise<CampaignTotalRedeemedCodesList> => {
  let url = `${ENDPOINT}/${id}/total-redeemed-codes`;

  if (query) {
    url += `?${query}`;
  }

  return axios
    .get(url)
    .then(
      (response: AxiosResponse<CampaignTotalRedeemedCodesList>) => response.data
    )
    .catch((error) => {
      return error;
    });
};
