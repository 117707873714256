import { FC } from "react";
import { Link } from "react-router-dom";

const Error403: FC = () => {
  return (
    <>
      {/* begin::Title */}
      <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Access Denied!</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className="fw-semibold fs-6 text-gray-500 mb-7">
        It appears that you are attempting to access content that you are not
        authorized to view.
      </div>
      {/* end::Text */}

      {/* begin::Link */}
      <div className="mb-0">
        <Link to="/dashboard" className="btn btn-sm btn-primary">
          Return Home
        </Link>
      </div>
      {/* end::Link */}
    </>
  );
};

export { Error403 };
