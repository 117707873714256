import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  GenericErrorMessage,
  genericOnChangeHandler,
} from "../../../helpers/form";
import { extractErrors } from "../../../helpers/requests";
import {
  defaultForgotPasswordFormFields,
  ForgotPasswordFormFields,
  forgotPasswordSchema,
} from "../core/_forms";

import { requestPassword } from "../core/_requests";

export function ForgotPassword() {
  const [form, setForm] = useState<ForgotPasswordFormFields>(
    defaultForgotPasswordFormFields
  );
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const handleForgotPassword = (e: any) => {
    setLoading(true);

    requestPassword(form).then((response) => {
      setLoading(false);

      if (axios.isAxiosError(response)) {
        // we need to show the errors
        setFormErrors(extractErrors(response));
      } else if (response === undefined) {
        // show generic error message
        setFormErrors([GenericErrorMessage]);
      } else {
        // we sent the request to reset password so we need to show success message
        setIsSuccess(true);
      }
    });
  };

  const onChangeHandler = (e: any) => {
    genericOnChangeHandler(e, form, setForm);
  };

  return (
    <>
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark mb-3 fs-2">Forgot Password?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-400 fw-bold fs-5">
          Enter your email to reset your password.
        </div>
        {/* end::Link */}
      </div>

      {formErrors && formErrors.length > 0 && (
        <Alert variant="danger" className="mx-0">
          <ul className="mb-0">
            {formErrors.map((errorMessage, index) => {
              return <li key={index}>{errorMessage}</li>;
            })}
          </ul>
        </Alert>
      )}

      {isSuccess && (
        <div className="alert alert-success d-flex align-items-center p-5">
          <i className="fa-solid fa-check text-success me-4 fs-1" />
          <div className="d-flex flex-column">
            <h4 className="mb-1 text-success">Success</h4>
            <span>
              The password reset request was sent. Check your inbox for further
              instructions.
            </span>
          </div>
        </div>
      )}

      <Formik
        initialValues={defaultForgotPasswordFormFields}
        validationSchema={forgotPasswordSchema}
        onSubmit={handleForgotPassword}
        enableReinitialize
      >
        {() => (
          <Form onChange={onChangeHandler}>
            <div className="mb-7">
              <Field
                className="form-control fs-6"
                type="text"
                placeholder="Enter email address"
                name="email"
              />

              <div className="mt-1 text-danger">
                <ErrorMessage name="email" className="mt-2" />
              </div>
            </div>

            <div className="d-flex justify-content-center mb-5">
              <Button type="submit" className={"me-2 w-100"}>
                Reset password
              </Button>
            </div>
            <div className="d-flex justify-content-center">
              <p>Remember it?</p> &nbsp;
              <Link to={"/auth/login"}>Sign in</Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
