export const formatDateToMonthDayYear = (date: string | undefined) => {
  // F d, Y => March, 7 2023
  if (date) {
    const fullDate = createDateFromString(date);

    return `${fullDate.toLocaleString("default", {
      month: "long",
    })} ${fullDate.getDate()}, ${fullDate.getFullYear()} ${
      date.split(" ")?.[1]
    }`;
  }

  return "";
};

export const createDateFromString = (date: string) => {
  // for the month we do -1 because new Date accepts month Index and not month
  return new Date(
    parseInt(date.split("-")[0]),
    parseInt(date.split("-")[1]) - 1,
    parseInt(date.split("-")[2])
  );
};

export const toDateTimeString = (date: Date) => {
  return (
    date.toDateString() +
    ", " +
    padLeft(date.getHours()) +
    ":" +
    padLeft(date.getMinutes()) +
    ":" +
    padLeft(date.getSeconds())
  );
};

const padLeft = (nr: any, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);

export const formatDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const date = new Date(dateString);

  return date.toLocaleDateString("en-US", options);
};

export const urlChecker = (input: any) => {
  const urlPattern =
    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

  return urlPattern.test(input);
};

export const capitalizeFirstLetter = (inputString: string | undefined) => {
  if (inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  } else {
    return "";
  }
};
