import clsx from "clsx";
import React from "react";
import { FormLabel } from "react-bootstrap";

type Props = {
  text: string;
  isRequired?: boolean;
};

const CustomFormLabel: React.FC<Props> = ({ text, isRequired = false }) => {
  return (
    <div>
      <FormLabel
        className={clsx("fs-base fw-semibold form-label mt-3", {
          required: isRequired,
        })}
      >
        {text}
      </FormLabel>
    </div>
  );
};

export default CustomFormLabel;
