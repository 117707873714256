import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { SuspenseView } from "../components/misc/SuspenseView";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import IamModuleRoutes from "./iam/ModuleRoutes";
import MiscModuleRoutes from "./misc/ModuleRoutes";

export const PrivateRoutes = () => {
  const CampaignsRoutes = lazy(() => import("./campaign/CampaignsRoutes"));
  const CodesRoutes = lazy(() => import("./campaign/CodesRoutes"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />

        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        {/* Pages */}

        {/* IAM section */}
        <Route path="iam/*" element={<IamModuleRoutes />} />

        {/* Misc section */}
        <Route path="misc/*" element={<MiscModuleRoutes />} />

        {/* Main section */}
        <Route
          path="campaigns/*"
          element={
            <SuspenseView>
              <CampaignsRoutes />
            </SuspenseView>
          }
        ></Route>

        <Route
          path="codes/validator/*"
          element={
            <SuspenseView>
              <CodesRoutes />
            </SuspenseView>
          }
        ></Route>

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};
