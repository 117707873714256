import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import toast, { Toaster, ToastOptions } from "react-hot-toast";
import { WithChildren } from "../../../_metronic/helpers";
import PendingIcon from "../../components/icons/Pending";
import { B360ToastType } from "../../helpers/variables";

type Alert = {
  message: string;
  type: B360ToastType;
};

type B360ContextProps = {
  alert: Alert | undefined;
  setAlert: Dispatch<SetStateAction<Alert | undefined>>;
  pageTitle: string;
  setPageTitle: Dispatch<SetStateAction<string>>;
};

const initB360ContextPropsState = {
  alert: undefined,
  setAlert: () => {},
  pageTitle: "",
  setPageTitle: () => {},
};

const B360Context = createContext<B360ContextProps>(initB360ContextPropsState);

const useB360App = () => {
  return useContext(B360Context);
};

const B360App: FC<WithChildren> = ({ children }) => {
  const [alert, setAlert] = useState<Alert | undefined>(undefined);
  const [pageTitle, setPageTitle] = useState<string>("");

  const color = {
    success: "#50cd89",
    error: "#f1416c",
    pending: "#d5441c",
    warning: "#FFA800",
  };

  const type = {
    success: alert?.type,
    error: alert?.type,
    pending: "success",
    warning: alert?.type,
  };

  const icon = {
    pending: <PendingIcon />,
    warning: "⚠️",
  };

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  useEffect(() => {
    if (alert !== undefined) {
      const options: ToastOptions = {
        id: `alert-${alert.type}`,
        duration: 4000,
        position: "top-center",
        style: {
          border: "1px solid " + (color as any)[alert.type],
          padding: "16px",
          color: "#000000",
        },
        iconTheme: {
          primary: (color as any)[alert.type],
          secondary: "",
        },
        ...(alert.type in icon ? { icon: (icon as any)[alert.type] } : {}),
      };

      if (
        ["success", "error", "loading", "blank", "custom"].includes(alert.type)
      ) {
        (toast as any)[(type as any)[alert.type]](alert.message, options);
      } else {
        (toast as any)(alert.message, options);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  return (
    <B360Context.Provider value={{ alert, setAlert, pageTitle, setPageTitle }}>
      {children}
      <Toaster />
    </B360Context.Provider>
  );
};

export { B360App, useB360App };
