export class RoutePermissionMapper {
  regexMap: Map<RegExp, string>;

  constructor() {
    this.regexMap = new Map<RegExp, string>();

    this.fillMap();
  }

  fillMap() {
    this.regexMap.set(/^\/iam\/[a-z-]+\/?$/, "view-iam");

    this.regexMap.set(/^\/iam\/[a-z-]+\/create\/?$/, "manage-iam");

    this.regexMap.set(/^\/iam\/[a-z-]+\/\d+\/edit\/?$/, "manage-iam");

    this.regexMap.set(/^\/misc\/[a-z-]+\/?$/, "view-misc");

    this.regexMap.set(/^\/misc\/[a-z-]+\/create\/?$/, "manage-misc");

    this.regexMap.set(/^\/misc\/[a-z-]+\/\d+\/edit\/?$/, "manage-misc");

    this.regexMap.set(/^\/campaigns\/?$/, "view-campaigns");

    this.regexMap.set(/^\/campaigns\/create\/?$/, "manage-campaigns");

    this.regexMap.set(/^\/campaigns\/\d+\/edit\/?$/, "manage-campaigns");

    this.regexMap.set(/^\/campaigns\/\d+\/delete\/?$/, "manage-campaigns");
  }
}
