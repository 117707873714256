import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CustomFormLabel from "../../../components/forms/CustomFormLabel";
import {
  GenericErrorMessage,
  genericOnChangeHandler,
} from "../../../helpers/form";
import { extractErrors } from "../../../helpers/requests";
import {
  defaultResetPasswordFormFields,
  ResetPasswordFormFields,
  resetPasswordSchema,
} from "../core/_forms";

import { resetPassword } from "../core/_requests";

export function ResetPassword() {
  const [searchParams] = useSearchParams();
  const [form, setForm] = useState<ResetPasswordFormFields>(
    defaultResetPasswordFormFields
  );
  const [token, setToken] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // get the search params and set the form's token and email to the values there
    if (searchParams.has("token")) {
      let token = searchParams.get("token");

      if (token) {
        setToken(token);
      }
    }

    if (searchParams.has("email")) {
      let email = searchParams.get("email");

      if (email) {
        setEmail(email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let updatedFormFields: ResetPasswordFormFields = {
      token: token,
      email: email,
      password: "",
      password_confirmation: "",
    };

    setForm(updatedFormFields);
  }, [token, email]);

  const onChangeHandler = (e: any) => {
    genericOnChangeHandler(e, form, setForm);
  };

  const handlePasswordReset = (e: any) => {
    // do API call to reset user and send confirmation email
    setLoading(true);
    // when done we need to redirect to login page

    resetPassword(form).then((response) => {
      setLoading(false);

      if (axios.isAxiosError(response)) {
        // we need to show the errors
        setFormErrors(extractErrors(response));
      } else if (response === undefined) {
        // show generic error message
        setFormErrors([GenericErrorMessage]);
      } else {
        setIsSuccess(true);

        // we were able to store the user
        setTimeout(() => {
          navigate("/auth/login");
        }, 3000);
      }
    });
  };

  return (
    <>
      <Alert variant="danger">
        <ul className="mb-0">
          {formErrors.map((errorMessage, index) => {
            return <li key={index}>{errorMessage}</li>;
          })}
        </ul>
      </Alert>

      {isSuccess && (
        <div className="alert alert-success d-flex align-items-center p-5">
          <i className="fa-solid fa-check text-success me-4 fs-1" />
          <div className="d-flex flex-column">
            <h4 className="mb-1 text-success">Success</h4>
            <span>
              The password was successfully changed. You will now be redirected
              to login with your new password.
            </span>
          </div>
        </div>
      )}

      <Formik
        initialValues={form}
        validationSchema={resetPasswordSchema}
        onSubmit={handlePasswordReset}
        enableReinitialize
      >
        {(formik) => (
          <Form onChange={onChangeHandler}>
            <div className="mb-7">
              <CustomFormLabel text="Email address" isRequired={true} />

              <Field
                className="form-control fs-6"
                type="text"
                placeholder="Enter email address"
                name="email"
                disabled={true}
              />
            </div>

            <Field className="form-control fs-6" type="hidden" name="token" />

            <div className="mb-7">
              <CustomFormLabel text="New password" isRequired={true} />

              <Field
                className="form-control fs-6"
                type="password"
                placeholder="Enter new password"
                name="password"
              />

              <div className="mt-1 text-danger">
                <ErrorMessage name="password" className="mt-2" />
              </div>
            </div>

            <div className="mb-7">
              <CustomFormLabel text="Repeat new password" isRequired={true} />

              <Field
                className="form-control fs-6"
                type="password"
                placeholder="Repeat new password"
                name="password_confirmation"
              />

              <div className="mt-1 text-danger">
                <ErrorMessage name="password_confirmation" className="mt-2" />
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <Button type="submit" className={"me-2"}>
                Submit
              </Button>

              <Link to={"/auth/login"}>
                <Button variant="light-secondary" type="submit">
                  Cancel
                </Button>
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
