/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { generatePageTitle } from "../../helpers/pageTitleGenerator";
import { Sections } from "../../helpers/sections";
import { PageTypes } from "../../helpers/variables";
import { useAuth } from "../../modules/auth";
import { useB360App } from "../../modules/general/B360App";
import CodeValidator from "../../sections/campaigns/pages/codes/Validator";
import CampaignIndex from "../../sections/campaigns/pages/Index";

const DashboardSection: FC = () => <></>;

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  const b360App = useB360App();
  const { currentUser, hasRoles } = useAuth();

  useEffect(() => {
    b360App.setPageTitle(
      generatePageTitle(Sections.DASHBOARD, PageTypes.INDEX)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>

      {hasRoles(currentUser, ["Campaign Manager"]) ||
      hasRoles(currentUser, ["Administrator"]) ? (
        <CampaignIndex />
      ) : hasRoles(currentUser, ["Restaurant Manager"]) ? (
        <CodeValidator />
      ) : (
        <DashboardSection />
      )}
    </>
  );
};

export { DashboardWrapper };
